<template>
  <div class="pt-3 pb-0 px-1">
    <v-data-table
      dense
      :headers="headers"
      :items="actas"
      :height="480"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">ACTAS</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" fab outlined small @click="initialize" v-bind="attrs" v-on="on">
                <v-icon>mdi-table-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip v-auth-acl="'gh-act-mis_actas-export'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Exportar BD</span>
          </v-tooltip>
        </div>
        <!--<v-toolbar flat>
          <v-toolbar-title class="black--text">ACTAS</v-toolbar-title>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            class="text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>-->
      </template>
      <template v-slot:[`item.codigo`]="{ item }">
        <v-chip color="primary" dark>{{ item.codigo }}</v-chip>
      </template>
      <template v-slot:[`item.tiporeferencia`]="{ item }">
        <span v-if="item.tiporeferencia == ''">ACTA DE REUNIÓN</span>
        <span v-if="item.tiporeferencia == 'S'">ACTA DE SEGUIMIENTO</span>
        <span v-if="item.tiporeferencia == 'E'">ACTA DE COMITÉ PERSONAL</span>
        <span v-if="item.tiporeferencia == 'C'">ACTA DE COMITÉ GH</span>
        <span v-if="item.tiporeferencia == 'T'">ACTA DE TAREA</span>
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-col cols="12" class="d-flex justify-space-around px-0">
          <v-tooltip bottom v-auth-acl="'gh-act-mis_actas-delete'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small color="error" class="mr-1" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                <v-icon small dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-act-mis_actas-edit'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="editActa(item)">
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-act-mis_actas-sendnotification'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="fileSend(item)">
                <v-icon small dark>
                  mdi-send
                </v-icon>
              </v-btn>
            </template>
            <span>Notificar</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-act-mis_actas-view'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small color="primary" v-bind="attrs" v-on="on" @click="viewActas(item)">
                <v-icon small dark>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </v-col>
      </template>
    </v-data-table>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <ActasReunionFormulario
      v-auth-acl="'gh-act-act_reu-insert'"
      :idreferencia="idreferencia"
      :referencia="referencia"
      :objectActa="objectActa"
      :idactaaux="idactaaux"
      :dialogActa="dialogActasEdit"
      @closeActasEdit="closeActasEdit"
    ></ActasReunionFormulario>
    <MiniActasReunionFormulario
      v-auth-acl="'gh-act-act_seg-insert'"
      :objectActaMini="objectActaMini"
      :idactaaux="idactaaux"
      :dialogActaSeguimiento="dialogActasEditMini"
      @closeActasEditMini="closeActasEditMini"
    ></MiniActasReunionFormulario>
    <ActasView
      v-auth-acl="'gh-act-mat_aac-view'"
      :object="objectViewActa"
      :dialog="dialogActasView"
      @closeActasView="closeActasView"
    ></ActasView>
  </div>
</template>

<script>
import MatrizActaService from '../services/MatrizActaService';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
  name: 'MatrizProyectoGeneral',
  components: {
    ActasReunionFormulario: () => import('./ActasReunionFormulario'),
    MiniActasReunionFormulario: () => import('./MiniActasReunionFormulario'),
    ActasView: () => import('./ActasView')
  },
  props: {
    dateRange: {
      type: Array,
      default: new Array()
    }
  },
  watch: {
    async dateRange(val) {
      const fecha = [];
      val.forEach((element) => {
        fecha.push(
          element
            .split('-')
            .reverse()
            .join('-')
        );
      });
      if (fecha.length === 2) {
        await this.initialize();
      }
    }
  },
  data: () => ({
    objectViewActa: {},
    dialogActasView: false,
    idreferencia: '',
    idactaaux: '',
    referencia: '',
    dialogActasEdit: false,
    dialogActasEditMini: false,
    objectActa: [],
    objectActaMini: [],
    showLoading: false,
    search: '',
    headers: [
      /*{ text: 'N°', value: 'numero', width: '70', class: 'primary' },*/
      { text: 'ACCIONES', value: 'accion', width: '120', class: 'primary' },
      { text: 'ACTA', value: 'acta', width: '150', class: 'primary' },
      { text: 'CODIGO', value: 'codigo', width: '150', class: 'primary' },
      { text: 'SUBTITULO', value: 'subtitulo', width: '150', class: 'primary' },
      { text: 'ESCRIBANO', value: 'nombreescribano', width: '250', class: 'primary' },
      { text: 'TIPO', value: 'tiporeferencia', width: '160', class: 'primary' },
      { text: 'FECHA CREACIÓN', value: 'fechacreacion', width: '160', class: 'primary' },
      { text: 'FECHA MODIFICACIÓN', value: 'fechamodifica', width: '160', class: 'primary' }
    ],
    actas: []
  }),
  computed: {},
  methods: {
    closeActasView() {
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };
      this.dialogActasView = false;
    },
    async closeActasEdit() {
      this.objectActa = [];
      this.dialogActasEdit = false;
      //await this.initialize();
    },
    async closeActasEditMini() {
      console.log('close actas edit mini');
      this.objectActaMini = [];
      this.dialogActasEditMini = false;
      //await this.initialize();
    },
    async viewActas({ idacta }) {
      this.showLoading = true;
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        tiporeferencia: '',
        nombresall: ''
      };

      const res = await this.matrizActaService.get().execResource('viewacta', {
        idacta: idacta
      });
      this.showLoading = false;
      this.objectViewActa = {
        agendas: res.agendas,
        areaescribano: res.areaescribano,
        arealider: res.arealider,
        compromisos: res.compromisos,
        escribano: res.escribano,
        idescribano: res.idescribano,
        idlider: res.idlider,
        idreferencia: res.idreferencia,
        invitados: res.invitados,
        lider: res.lider,
        numeroacta: res.numeroacta,
        porcparticipantes: res.porcparticipantes,
        responsable: res.responsables,
        subtitulo: res.subtitulo,
        tiporeferencia: res.tiporeferencia,
        titulo: res.titulo,
        codigo: idacta
      };
      this.dialogActasView = true;
    },
    async fileSend(item) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea notificar el acta?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const res = await this.matrizActaService.get().execResource('filesend', {
          idempresa: '001',
          idacta: item.idacta,
          tipo: item.tiporeferencia
        });
        this.showLoading = false;
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async editActa({ idacta }) {
      this.showLoading = true;
      this.objectActa = [];
      this.objectActaMini = [];

      const res = await this.matrizActaService.get().execResource('viewacta', {
        idacta: idacta
      });
      this.referencia = res.tiporeferencia;
      this.idreferencia = res.idreferencia;
      this.idactaaux = idacta;
      if (this.referencia == 'S') {
        this.dialogActasEditMini = true;
        this.objectActaMini.push(res);
      } else {
        this.dialogActasEdit = true;
        this.objectActa.push(res);
      }

      this.showLoading = false;
    },
    async exportarbase() {
      this.showLoading = true;
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MISACTAS-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      await this.matrizActaService.downloadResource(
        'exportar',
        {
          token: token,
          fecha1:
            this.dateRange.length > 0
              ? Date.parse(this.dateRange[0]) < Date.parse(this.dateRange[1])
                ? this.dateRange[0]
                : this.dateRange[1]
              : '',
          fecha2:
            this.dateRange.length > 0
              ? Date.parse(this.dateRange[0]) > Date.parse(this.dateRange[1])
                ? this.dateRange[0]
                : this.dateRange[1]
              : ''
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async initialize() {
      this.showLoading = true;
      this.actas = [];

      const res = await this.matrizActaService.get().execResource('listaacta', {
        fecha1:
          this.dateRange.length > 0
            ? Date.parse(this.dateRange[0]) < Date.parse(this.dateRange[1])
              ? this.dateRange[0]
              : this.dateRange[1]
            : '',
        fecha2:
          this.dateRange.length > 0
            ? Date.parse(this.dateRange[0]) > Date.parse(this.dateRange[1])
              ? this.dateRange[0]
              : this.dateRange[1]
            : ''
      });
      if (res.length > 0) {
        res.forEach((el, index) => {
          this.actas.push({
            numero: index + 1,
            acta: el.titulo,
            codigo: el.codigo,
            subtitulo: el.subtitulo,
            fechacreacion: moment(el.fechacrecion).format('DD-MM-YYYY HH:mm'),
            fechamodifica: moment(el.fechaalter).format('DD-MM-YYYY HH:mm'),
            idacta: el.idacta,
            tiporeferencia: el.tiporeferencia,
            nombreescribano: el.nombreescribano
          });
        });
      } else {
        this.actas = [];
      }

      this.showLoading = false;
    },
    async deleteItem({ idacta }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea eliminar el acta?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const restable = await this.matrizActaService.delete().execResource('eliminar', { id: idacta });
        this.showLoading = false;
        if (restable.status) {
          await this.alertDialog('success', restable.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', restable.data);
        }
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    }
  },
  async mounted() {},
  async created() {
    this.matrizActaService = this.$httpService(new MatrizActaService(), this);
    await this.initialize();
  }
};
</script>
<style lang="scss">
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}
</style>
