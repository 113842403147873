var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-3 pb-0 px-1"},[_c('v-data-table',{staticClass:"border",attrs:{"dense":"","headers":_vm.headers,"items":_vm.actas,"height":480,"multi-sort":"","mobile-breakpoint":0,"items-per-page":-1,"no-results-text":"No se encontraron datos","no-data-text":"No se encontraron datos","search":_vm.search,"item-class":"class","fixed-header":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-space-between align-center pa-2"},[_c('div',{staticClass:"black--text"},[_vm._v("ACTAS")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0 text-field-search-matriz",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscar","background-color":"rgb(220, 220, 220, 0.16)","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","fab":"","outlined":"","small":""},on:{"click":_vm.initialize}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mis_actas-export'),expression:"'gh-act-mis_actas-export'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","small":""},on:{"click":_vm.exportarbase}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Exportar BD")])])],1)]},proxy:true},{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(item.codigo))])]}},{key:"item.tiporeferencia",fn:function(ref){
var item = ref.item;
return [(item.tiporeferencia == '')?_c('span',[_vm._v("ACTA DE REUNIÓN")]):_vm._e(),(item.tiporeferencia == 'S')?_c('span',[_vm._v("ACTA DE SEGUIMIENTO")]):_vm._e(),(item.tiporeferencia == 'E')?_c('span',[_vm._v("ACTA DE COMITÉ PERSONAL")]):_vm._e(),(item.tiporeferencia == 'C')?_c('span',[_vm._v("ACTA DE COMITÉ GH")]):_vm._e(),(item.tiporeferencia == 'T')?_c('span',[_vm._v("ACTA DE TAREA")]):_vm._e()]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex justify-space-around px-0",attrs:{"cols":"12"}},[_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mis_actas-delete'),expression:"'gh-act-mis_actas-delete'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mis_actas-edit'),expression:"'gh-act-mis_actas-edit'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editActa(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mis_actas-sendnotification'),expression:"'gh-act-mis_actas-sendnotification'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.fileSend(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-send ")])],1)]}}],null,true)},[_c('span',[_vm._v("Notificar")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mis_actas-view'),expression:"'gh-act-mis_actas-view'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.viewActas(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])],1)]}}],null,true)}),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('ActasReunionFormulario',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-act_reu-insert'),expression:"'gh-act-act_reu-insert'"}],attrs:{"idreferencia":_vm.idreferencia,"referencia":_vm.referencia,"objectActa":_vm.objectActa,"idactaaux":_vm.idactaaux,"dialogActa":_vm.dialogActasEdit},on:{"closeActasEdit":_vm.closeActasEdit}}),_c('MiniActasReunionFormulario',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-act_seg-insert'),expression:"'gh-act-act_seg-insert'"}],attrs:{"objectActaMini":_vm.objectActaMini,"idactaaux":_vm.idactaaux,"dialogActaSeguimiento":_vm.dialogActasEditMini},on:{"closeActasEditMini":_vm.closeActasEditMini}}),_c('ActasView',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mat_aac-view'),expression:"'gh-act-mat_aac-view'"}],attrs:{"object":_vm.objectViewActa,"dialog":_vm.dialogActasView},on:{"closeActasView":_vm.closeActasView}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }